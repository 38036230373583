/** Variables */

/** Font */
$font__base-size: 14px;
$font__legend-size: 21px;
$font__family-1: "Roboto-Light", sans-serif;
$font__family-2: "Helvetica Neue", Helvetica, Arial, sans-serif;

/** Colors */
$color__black-1: 	#3E3E3E;
$color__black-2: 	#2C3E50;
$color__black-3: 	#333;
$color__black-4:  #142C3F;

$color__white-1: 	#FFFFFF;
$color__white-2: 	#F2F2F2;

$color__teal-1: 	#46B5C0;
$color__teal-2:		#DAF0F2;

$color__blue-1: 	#006293;
$color__blue-2:		#337AB7;
$color__blue-3: 	#1274B2;
$color__blue-4: 	#4190C1;
$color__blue-5:   #0F6397;
$color__blue-6:   #85CDED;

$color__light-blue-1: #B7D5E8;

$color__dark-1:   #142C3F;
$color__dark-2:   #566573;

$color__green-1:	#279C38;

$color__yellow-1:	#FFCC00;

$color__red-1:	#FF0000;

$color__grey-1: #D8DDE6;

$color__dark-grey-1: #333333;

$color__light-grey-1: #ECF4F9;
$color__light-grey-2: #e5e5e5;

$color__orange-1: #F79336;

/** Shared Variables */
$font__color-main: 	$color__black-1 !default;
$font__color-dark: 	$color__black-2 !default;
$font__color-light: $color__white-1 !default;


$nav__color-background: $color__blue-3 !default;
$nav__color-background-active: $color__white-1 !default;
$nav__color-font: $font__color-light !default;
$nav__color-font-active: $font__color-dark !default;

$header__color-background: $color__blue-3 !default;

$navbar__color-background: $color__blue-3 !default;
$navbar__color_background-2: $color__blue-4 !default;

$navbar__color-hover-1: $color__blue-5 !default;

$link__color: $color__blue-3 !default;

$bg__color-primary-1: $color__blue-3 !default;
$bg__color-primary-2: $color__light-blue-1 !default;
$bg__color-light:     $color__white-1 !default;
$bg__color-light-2:   $color__white-2 !default;
$bg__color-dark:      $color__black-1 !default;
$bg__color-dark-2:    $color__black-2 !default;
$bg__color-dark-3:    $color__black-4 !default;
$bg__color-light-dark:$color__light-grey-1 !default;

$font__color-dark:    $color__black-1 !default;
$font__color-light:   $color__white-1 !default;
$font__color-light-2: $color__white-2 !default;

$button__color-primary-1: $color__blue-3 !default;
$button__color-primary-2: $color__blue-4 !default;

$button__color-hover-1: $color__blue-2 !default;

$icon__star-active: $color__orange-1 !default;

$anchor__primary-light: $color__blue-6 !default;

$column__spacing: 15px !default;