@function font-path($path) {
  @return $path;
}

@import "_custom_variables";

$color__active: #5CC83B;
$color__inactive: #999999;

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #A72925 !default;
$color__red:            #9D4A46;

@import "../../../assets/styles/scss/style"; // src styling import
//@import "~@optum-wvie/dynamic-ui-shared/styles/style.scss";
@import "./bootstrap/bootstrap.scss";

html {
  font-size: 75%
}

$color__blue: #1274B2;
$color__bg-dark: #FFF;
$color__bg-light: #FFF;
$color__bg-light2: #F4F5F7;
$color__bg-light-dark: #efefef;
$font__color-light: #FFF;
$font__color-dark2: #545454;
$color__grey-1: #D8DDE6;

$sidebar-width: 240px;
$header-height: 60px;
$nav-bg-color: #1274B2;
$text-color-light: #FFF;
$content-width: 1920px;
$logo-bg-color: $color__blue;

$logo-margin-top: 10px;
$nav-icon-multiplier: 2.5;

$triangle-px: 10px;

.xs-font {
  .field {
    font-size: $font-size-sm;
  }
}

.table-condensed {
  .toggle {
    .btn-xs {
      padding: 1px 5px;
    }
  }
}

.badge, .bg-primary {
  background: $brand-primary !important;
  border: 1px solid $brand-primary !important;
  color: $body-bg;
}

.fa,
.fas {
  /*font-family: 'Font Awesome 5 Free';*/
  width: 30px;
  font-weight: 900;
  font-size: 1rem * $nav-icon-multiplier;
}

html, body{
  height: 100%;
}

.landing-page{
  
  .bg{
    background: url("/assets/imgs/forest-bg.jpg");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .container{
    margin-top: 15%;
    background: rgba(255,255,255,0.6);
    border-radius: 15px;
    max-width: 500px;
    //min-height: 100px
  }
}

.login-page{
  background-image: url("/assets/backgrounds/nature-bridge.png");
  background-size: cover;
  height: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

    .btn{
      font-size: 1.5rem;
      width: 100%;
  }
}

.main-content{
  height: 95%;
}

.login-section{
  background: rgba( 0, 0, 0, 0.8);
  border-radius: 10px;
  color: $text-color-light;
  max-width: 480px;
  padding: 50px;
  margin: auto;
  margin-top: 50px;

  h1{
    font-size: 2.5rem;
  }

  h2{
    font-weight: 700;
    font-size: 3rem;
    margin-top: 0;
  }

  a{
    color: $text-color-light;
  }
}

.form-control{
  border-radius: 2px;
  display: inline;
}

.logo{
  width: 150px;
}

.title-section{
  padding: 35px 0;
}

.login-footer{
  width: 100%;
  background-color: #000;
  position: absolute;
  bottom: 0;
}

.footer-section{
  color: $text-color-light;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15%;

  a{
    color: $text-color-light;
  }
}

.vertical-bar{
  padding: 0 10px;
  &::after{
    content: "";
    border-left: $text-color-light 1px solid;
    height: 10px;
    width: 1px;
  }
}

.admin-logo{
  margin: $logo-margin-top auto;
  height: $header-height - ($logo-margin-top * 2);
}

.no-gutter{
  padding-left: 0;
  padding-right: 0;
}

.no-padding{
  padding: 0;
}

.table-btn-padding{
  padding: 3px !important;
}

.content-container{
  background-color: $color__bg-light2;
  position: relative;
  width: 100%;
  max-width: $content-width;
  min-height: 500px;
  height: 100%;

}

.content-wrapper{
  flex-grow: 1;
}

body {
  color: $font__color-main;
  font-family: $font__family-2;
  background-color: #566573;
}

.main-logo-link{
  height: 100%;

  float: left;
}

.main-logo-wrapper{
  width: $sidebar-width;
  height: 100%;
  background-color: $logo-bg-color;

  display: inline-block;
}

.main-sidebar{
  min-width: $sidebar-width;
  background: $nav-bg-color;
  min-height: 80vh;
  color: $text-color-light;
  z-index: 5;
}

.main-header{
  height: $header-height;
  //max-height: $header-height;

  position: relative;
  z-index: 10;

  background-color: $color__blue;
  border-bottom: 1px solid darken($color__bg-light2, 10%);

  .content{
    padding-top: 10px;
  }

  .admin-portal-title{
    font-size: 2.5rem;
    color: $font__color-light;
  }

  .search-input{
    vertical-align: text-bottom;
    margin-left: 15px;
  }

  .admin-search-btn{
    vertical-align: super;
    margin-left: 15px;
  }

  .dropdown-toggle{
    border: none;

    &:hover, &:active, &:focus{
      background-color: transparent;
    }
  }
  
}

.icon{
  color: white;
  width: 75px;
  height: 75px;

  i{
    font-size: 3.5rem;
  }

  .fa{
    margin: 22px;
    
  }
}

.icon-color {
  color: #337ab7;
}

.icon-circle{
  border-radius: 100px;
  margin: auto;
}

.bg-blue{
  background-color: #3276B1;
}

.bg-green{
  background-color: #159077;
}

.ie-block{
  background-color: white;
  border: 1px solid lightgrey;
  padding:15px
}

.no-ie-block{
  color: $font__color-dark2;

  &:first-child{
    &::after{
      content:"";
      position: absolute;
      right: 0;
      height:100%;
      border-right: 1px solid darkgrey;
    }
  }
}

.nav-tabs-list{
  list-style: none;

  a{
    color: $text-color-light;
    text-decoration: none;
  }

  > li{


    > a{
      padding-top: 5px;
      padding-bottom: 5px;
      display: block;

      &.active{
        background-color: $color__bg-dark;
        color: #3e3e3e;
        border-style: double;
        border-width: 4px;
        border-radius: 0;
      }
    }

    &.active{
      background-color: $color__bg-dark;
    }
  }

  .fas{
    padding-right: 10px;
  }

  .nav-text{
    vertical-align: super;
    margin-left: 15px;
  }
}

.ie-block-sm{
  height: 225px;
}

.grey-line-right{
  border-right: 1px solid darkgrey;
}

.xs-gutter{
  padding-left:2px;
  padding-right: 2px;
}

.bg-white{
  background-color: $color__bg-light;
}

.vert-padding{
  padding-top: 15px;
  padding-bottom: 15px;
}

.table{

  margin:auto;
  margin-bottom: 1.33rem;

  &.table-sm .form-group {
    margin: 0.3rem;
  }

  

  &>tbody{
    &>tr{
      &>td{
        border: none;
      }
    }
  }

  // &.horizontal-scrollbar {
  //   display: block;
  //   overflow-x: auto;
  //   white-space: nowrap;
  // }
  .btn-link {
    padding: 0rem;
  }
}

.icon{
  &.circle{
    border-radius: 100px;
  }
}

.border{
  border: 1px solid #ddd;
}

.filter-item{
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.icon-status{
  height: 10px;
  width: 10px;
  background-color: $color__inactive;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;

  &.active{
    background-color: $color__active;
  }
}

.card-group {
	flex-flow: column wrap;
	.card {
		margin-top: 5px;
	}
	div:first-child {
		.card {
			margin-top: 0px;
		}
	}
}

.collapsible-card-group {
	.card-group {
		.card>.card-header {
      background-color: $color__light-blue-1;
      padding: 0;
			.card-title {
				button {
          height: 100%;
          width: 100%;
          font-size: 1.167rem;
          color: inherit;
					display: inline-flex;
					&::before {
						font-family: FontAwesome;
						padding-right: 5px;
						content: '\F0D7';
						// minus 
					}
					&.collapsed {
						&::before {
							content: '\F0DA';
							// plus 
						}
          }
          &:hover {
            text-decoration: none;
          }
          &:focus {
            text-decoration: none;
            background-color: #ffffff;
            color: #1274b2;
          }
				}
			}
		}
	}
}

.requiredBar input[type='text'], .requiredBar textarea {
	border-left: 4px solid red;
	border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.requiredBar>.card-header {
	border-left: 4px solid red !important;
	border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.requiredBar select {
	border-left: 4px solid red;
	border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.requiredBar .Select {
	border-left: 4px solid red;
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
}

td .btn.btn-end-form {
  margin: inherit;
  padding: inherit;
  margin-top: 6px;
}

label {
  font-weight: 700 !important;
  font-size: $font__base-size;
}

.paragraph-label {
  margin-right: 5px;
}

.org-cards {
  display: table-row;
  .card {
    margin-top: 5px;
    margin-bottom: 5px;
    .card-body {
      padding: 10px;
      * {
        display: table-cell;
        vertical-align: middle;
      }
      .small {
        font-size: $font-size-sm;
      }
    }
  }
  .card.selected {
    background-color: $gray-lighter;
    border-color: $brand-primary;
    color: $brand-primary;
    border: 2px solid;
  }
}
.admin-titles{
  .btn{
    &.btn-link{
      font-size: 21px;
      &.active{
        color: #000;
  
        &:hover{
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
}
.account-menu {
  color: #f2f2f2;
  
  .btn-link {
    color: #f2f2f2;
  }
  .user-btn{
    color: #f2f2f2;
    border: none;
    background: none;
    box-shadow: none;
    font-size: 16px;
    &:hover, &:active, &:focus{
      border: none;
      background: none;
      box-shadow: none;
    }
  }
}

.nav-popover{
  font-size: 14px;
  color: black;
  border: none;
  &:hover, &:focus {
    text-decoration: none;
    border: none;
    color: black;
  }
}

.default-role-org-radio{
  text-align: center;
  margin-top: 10px;
  input[type="radio"] {
    -ms-transform: scale(1.25); /* IE 9 */
    -webkit-transform: scale(1.25); /* Chrome, Safari, Opera */
    transform: scale(1.25);
  }
}
.nav-panel-heading {
  background-color: $nav-bg-color;
  border-color: $nav-bg-color;
  &:hover{
    background-color: $color__bg-dark;
  }
}

.nav-panel {
  margin-bottom: 0px;
  background-color: $nav-bg-color;
  border: none;
  box-shadow: none;
}

.Select.requiredBar{
  border-left: 4px solid red;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-bottom-0px {
  margin-bottom: 0px;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}
.margin-right-5px {
  margin-right: 5px;
}
.margin-left-15px {
  margin-left: 15px;
}
.alert>ul {
  list-style-type: none;
}

.col-md-offset-right-4 {
  margin-right: 33.33333333%;
}

.word-break-break-all {
  word-break: break-all;
}

.blank-label {
  margin-top: 5px;
}

label#root_inactiveAfterUnit_label {
  font-size: 0;
}

.mcr {
  margin-bottom: 0px;
}

.td-text {
    padding-top: 10px;
}

.dashboard-pie-chart {
  width: 500px;
  height: 500px;
  padding-top: 20px;
  margin-left: 15px;
  border-color: #DDDDDD;
  border-style: solid;
  border-width: thin;
  h2 {
    text-align: center;
  }
  table {
    width: inherit;
    margin-top: 10px;
    thead > tr > th {
      text-align: center;
    }
    tbody > tr > td:first-child {
      width: 28px;
    }
  }
}
.btn-link-danger {
  color: $color__red;
  text-decoration: underline;
}
.rdl-control-container{
  height: 100%;
}

.sf-indicator{
  color: green;
  font-weight: bold;
}

.org-attribute{
  border-bottom: 1px solid $color__grey-1;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-group.message-group {
  list-style: none;
}

.required-asterisk {
  font-size: 1.4em;
  line-height: 0;
}

.light-border {
  border: 1px solid #00000030;
  border-radius: 10px;
}

.clear-left{
  clear: left
}

.btn-margins {
  button {
    margin: 2px;
  }
}

.content-border {
  background-color: $color__bg-light;
  border-color: #DDDDDD;
  border-style: solid;
  border-width: thin;
  margin-bottom: 10px;
}

.label-space {
  margin-top: 22px;
}

.sso-link {
  background-color: $color__bg-light;
  border-color: #DDDDDD;
  border-style: solid;
  border-width: thin;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
  padding: 5px;
  margin-left: 15px;
}

.pagination {
  margin: 1.333rem 0rem;
}
.Select-control {
  .Select-clear-zone{
    color: #666;
  }
  .Select-placeholder{
    color: #575757;
  }
  .Select-multi-value-wrapper .Select-value{
    color: #31708f;
  }
}
.read-only-message-field {
  padding-top: 30px;
}

.column-word-break {
  word-break: break-all;
}
.assignment-rule-table {
  th {
    max-width: 40px;
  }
  td {
    max-width: 70px;
  }
}

.messageList span[id*="messageText"] , .userAccessList span[id*="_name"] {
  white-space: pre-wrap;
  word-break: break-word;
}
.dashboard .btn-link-danger{
  color: #721c24;
}
.dashboard .btn-info{
  background-color: #0E7181;
  border-color: #0E7181;
}

.btn-info {
  background-color: #31708f;
  border-color: #31708f;
}

.btn-info:hover, .btn-info:focus .btn-info:active {
  background-color: #285C76;
}

.align-center{
  text-align: center;
}

.dashboard caption{
  color: $font__color-dark2 ;
}

.messageList {
  .btn-primary{
    background-color: #1274b2;
  }
 
}
.editMessage, .createMessage {
  .btn-outline-secondary, .close{
    color: $font__color-dark2;
  }
  .btn-primary{
    background-color: #1274b2;
  }
}
@media only screen and (max-width: 920px) {
  /* For tablets: */
  .dashboard .col-1 {
    margin:  10px auto;
    max-width: 20%;
  }
}

.btn-primary {
  background-color: $button__color-primary-1;
  border-color: $button__color-primary-2;

  &:hover,
  &:focus,
  &:active:focus {
    background-color: $button__color-hover-1;
    border-color: $button__color-primary-2;
  }
}

.btn-outline-secondary {
  background-color: $white;
}

.assignment-rule-table .btn-outline-primary{
  color: #0062cc;
  border-color: #0062cc;
}
#root_addBtn {
  .fa {
    font-size: 1.333333rem
  }
}

#side-nav a:focus {
	outline-color: #FFFF00 !important;
	outline-width: 3px !important;
  outline-style: solid !important;
}

.rjsf :focus,
.form-section :focus,
.rjsf a:hover{
outline: 2px solid #1274b2 !important;
}

// .rjsf .btn:focus,
// .update-focus:focus,
// .pagination a:focus{
// background-color: #ffffff !important;
// color: #1274b2 !important;
// }

.main-header a:focus,
.skip:focus {
border: 2px solid #FFFF00;
box-shadow: none !important;
font-weight: bold;
outline:none;
}


.section-header-blue {
  margin-top: 10px;
  color: black;
  background-color: #B7D5E8;
  border-color: #B7D5E8;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  h5, h1, h2, h3, h4, h6 {
      margin-top: 10px;
      margin-bottom: 10px;
  }
}

.searchHeader {
  font-size: 1.6rem;
    padding: 10px 0px;
    margin: 0;
}

@media screen and (-ms-high-contrast: active) {
  .btn:focus{
    border: 2px solid #1274b2 !important;
  }
}

@media (min-width: 992px) {
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
}


.accordion{
  .card{
    .btn-link {
      color: #24292d;
      text-align: left;
      font-weight: 700;
    }
    .btn-link:hover, .btn-link:focus  {
      text-decoration: initial;
    }
  }
 .card-header{
     // border-top:2px solid #0880f1;
      padding: .25rem;
      background-color: #b7d5e8;
      border-radius: 0;
     .card-title{
         text-align:left;
         h5 {
             margin: 0;
             padding: .375rem .75rem;
             line-height: 1.5;
             font-size: 16px;
             font-weight: 600;
         }
         h4 {
             margin: 0;
             padding: .375rem .75rem;
             line-height: 1.5;
             font-size: 16px;
             font-weight: 600;
         }
         h2 {
             margin: 0;
             padding: .375rem .75rem;
             line-height: 1.5;
             font-size: 16px;
             font-weight: 600;
         }
         h3 {
             margin: 0;
             padding: .375rem .75rem;
             line-height: 1.5;
             font-size: 16px;
             font-weight: 600;
         }
         span[role="presentation"]{
             margin: 0;
             padding: .375rem .75rem;
             line-height: 1.5;
             font-size: 16px;
             color: #212529;
             display: block;
             font-weight: 600;
         }
         .fa{
            width: initial;
            font-weight: normal;
            font-size: 100%;
          }
     }
 } 
}
ul.error-detail.bs-callout.bs-callout-info {
  list-style: none;
  padding-left: 0px;
}
.align-right{
  text-align: right;
}

.tab-content-header{
  margin: 0;
}

.disable-a {
  pointer-events: none;
  background-color: grey;
}


.btn-primary{
  &:disabled{
    background-color: $brand-primary;
  }
}

.footer_div {
  width: 100%;
  height: 150px;
  background-color: #566573;
  position: relative;
  /* padding: 10px; */
}
.body-content {
  margin-bottom: 30px;
}

.displayNone {
  display: none;
}
.footer {
  padding-top: 5px;
  font-size: 14px;
  a {
    color: #98edff;
    &:hover {
      text-decoration: none;
    }
  }
}

.account-button{
  &:before{
    font-family: "Glyphicons Halflings",sans-serif;
    content: "\E008";
    padding-right: 3px;
  }
  &:after{
    font-family: "Glyphicons Halflings",sans-serif;
    content: "\E236";
    padding-left: 3px;
  }
}

.form-group > .form-group {
  margin-bottom: 0;
}

.panel.errors {
  margin-bottom: 15px;
}

#user-access-request {
  .panel{
    .card-header{
      background-color: #B7D5E8;
      padding: 0;
      .btn-link{
        text-align: left;
        font-size: 1.5rem;
        padding: 5px;
        text-decoration: none;
        color: black;
        font-weight: 400;
      }
    }
  }
}
